@font-face {
  font-family: "filter-icon-font";
  src: url("../Fonts/icon-font.eot");
  src: url("../Fonts/icon-font.eot?#iefix") format("embedded-opentype"), url("../Fonts/icon-font.woff") format("woff"), url("../Fonts/icon-font.ttf") format("truetype"), url("../Fonts/icon-font.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "filter-icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="filter-icon-"]:before,
[class*=" filter-icon-"]:before {
  font-family: "filter-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.filter-icon-angle-up:before {
  content: "\61"; }

.filter-icon-angle-down:before {
  content: "\62"; }

.filter-icon-list:before {
  content: "\63"; }

.filter-icon-grid:before {
  content: "\64"; }

.card-list .card,
.card-list .sliding-card {
  position: relative;
  background: #F0F0F0;
  font-size: 15px; }
  .card-list .card--image img,
  .card-list .sliding-card--image img {
    width: 100%;
    height: auto; }
  .card-list .card--header-text,
  .card-list .sliding-card--header-text {
    padding: 15px 30px 15px 0; }
    .card-list .card--header-text h3,
    .card-list .sliding-card--header-text h3 {
      font-size: 18px;
      line-height: 1.5;
      min-height: 50px; }
    .card-list .card--header-text p,
    .card-list .sliding-card--header-text p {
      margin-bottom: 0; }
  .card-list .card--body,
  .card-list .sliding-card--body {
    padding: 15px; }
    .card-list .card--body .btn,
    .card-list .sliding-card--body .btn {
      margin-top: 25px; }
    .card-list .card--body h4,
    .card-list .sliding-card--body h4 {
      font-size: 16px; }
  .card-list .card--dropdown-btn,
  .card-list .sliding-card--dropdown-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    cursor: pointer; }
    .card-list .card--dropdown-btn i,
    .card-list .sliding-card--dropdown-btn i {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center; }

@media (max-width: 1199px) {
  .card-list .card--header-text h3 {
    min-height: 35px; } }

@media (max-width: 575px) {
  .card-list .card--header-text {
    padding-left: 30px; } }

.card-list h1 {
  font-size: 24px; }

.card-list h2 {
  font-size: 20px; }

.card-list .title-bar {
  background: #F0F0F0;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between; }
  .card-list .title-bar--left {
    height: 60px;
    padding-left: 15px;
    display: flex;
    align-items: center; }
    .card-list .title-bar--left h1 {
      text-transform: uppercase;
      margin-bottom: 0; }
  .card-list .title-bar--right {
    display: flex;
    align-items: center;
    padding: 8px 0; }
  .card-list .title-bar .btn {
    background: #FFFFFF;
    cursor: pointer;
    margin-right: 10px;
    padding: 10px 16px; }
    .card-list .title-bar .btn.active, .card-list .title-bar .btn:hover {
      color: #FFFFFF;
      background: #4B4B4D; }
    .card-list .title-bar .btn i {
      vertical-align: middle; }
  .card-list .title-bar .filter-list {
    display: flex;
    margin-right: 5px; }
    .card-list .title-bar .filter-list--select {
      display: inline-block;
      margin-right: 10px;
      width: 120px;
      overflow: hidden;
      background: #FFFFFF;
      color: #4B4B4D;
      position: relative; }
      .card-list .title-bar .filter-list--select:before {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
      .card-list .title-bar .filter-list--select:hover {
        background: #F0F0F0; }
      .card-list .title-bar .filter-list--select select {
        padding: 9px 8px;
        width: 130%;
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none; }
        .card-list .title-bar .filter-list--select select:focus {
          outline: none; }

.card-list .filter-config {
  margin-bottom: 40px; }
  .card-list .filter-config h2 {
    margin-bottom: 15px; }
  .card-list .filter-config--list {
    min-height: 30px; }
    .card-list .filter-config--list + h2 {
      margin-top: 20px; }
  .card-list .filter-config--category {
    background: #FFFFFF;
    min-height: 20px;
    min-width: 80px;
    padding: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
    display: inline-block; }

.card-list .grid > .neos-contentcollection {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  .card-list .grid > .neos-contentcollection > .neos-empty-contentcollection-overlay {
    width: 100%; }

.card-list .grid .card--wrapper,
.card-list .grid .sliding-card--wrapper {
  position: relative;
  flex: 0 0 50%;
  max-width: 50%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 40px; }

.card-list .grid .neos-nodetypes-headline {
  padding: 0 15px 20px;
  width: 100%; }

.card-list .list .list-item,
.card-list .list .list-item--link {
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; }

.card-list .list .list-item--name {
  width: 50%; }

.card-list .list .list-item--type {
  width: 20%; }

.card-list .list .list-item--place {
  width: 20%; }

.card-list .list .list-item--year {
  width: 10%; }

.card-list .list .list-item + h2 {
  margin-top: 20px; }

@media (max-width: 991px) {
  .card-list .title-bar .filter-list {
    display: none; } }

@media (max-width: 767px) {
  .card-list .grid .sliding-card--wrapper {
    max-width: 100%;
    flex: 0 0 100%; } }
